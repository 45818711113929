body {
  background-color: #f6f6f6;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

h1 {
  margin: 0;
  margin-bottom: 20px;
  margin-top: 20px;
}

h2 {
  margin: 0;
  margin-bottom: 17px;
  margin-top: 17px;
}

h3 {
  margin: 0;
  margin-bottom: 16px;
  margin-top: 16px;
}

h4 {
  margin: 0;
  margin-bottom: 18px;
  margin-top: 18px;
}

h5 {
  margin: 0;
  margin-bottom: 20px;
  margin-top: 20px;
}

h6 {
  margin: 0;
  margin-bottom: 22px;
  margin-top: 22px;
}

p {
  margin: 0;
  margin-bottom: 14px;
  margin-top: 14px;
}
